import './style.css'

import gsap from 'gsap'
import unmuteIosAudio from 'unmute-ios-audio';
import SplitType from 'split-type'


//gsap blur plugin
(function() {
    const blurProperty = gsap.utils.checkPrefix("filter"),
          blurExp = /blur\((.+)?px\)/,
          getBlurMatch = target => (gsap.getProperty(target, blurProperty) || "").match(blurExp) || [];
  
    gsap.registerPlugin({
      name: "blur",
      get(target) {
        return +(getBlurMatch(target)[1]) || 0;
      },
      init(target, endValue) {
        let data = this,
            filter = gsap.getProperty(target, blurProperty),
            endBlur = "blur(" + endValue + "px)",
            match = getBlurMatch(target)[0],
            index;
        if (filter === "none") {
          filter = "";
        }
        if (match) {
          index = filter.indexOf(match);
          endValue = filter.substr(0, index) + endBlur + filter.substr(index + match.length);
        } else {
          endValue = filter + endBlur;
          filter += filter ? " blur(0px)" : "blur(0px)";
        }
        data.target = target; 
        data.interp = gsap.utils.interpolate(filter, endValue); 
      },
      render(progress, data) {
        data.target.style[blurProperty] = data.interp(progress);
      }
    });
  })();


window.addEventListener("resize", () => {
  const body = document.querySelector("body")
  body.style.width = window.innerWidth
  body.style.height = window.innerHeight
})


const tl = gsap.timeline({
  repeat: -1
})

const wordsWheel = document.querySelectorAll(".words-wheel h2")
console.log(wordsWheel)
console.log("word height = ", wordsWheel[0].clientHeight)

for (let i = 0; i<wordsWheel.length; i++)
{
  tl.to(".words-wheel", {
    y: -30 * i - 1,
    duration: 1,
    delay: 1
  })
}

const text = new SplitType(".title-container h1")

for (let i = 0; i< text.lines.length; i++)
{
  text.lines[i].classList.add("overflow-hidden")

}

console.log("text = ", text)

gsap.from(text.words, {
  y: 55,
  duration: 1,
  stagger: 0.1
})
